import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import { useIsAuthenticated } from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react";

import useIsBrowser from "../utils/useIsBrowser";

const calculateDimensions = (state: any) => {
    const ratio = 9 / 16;
    const wheight = state.height;
    const wwidth = state.width;
    return {
        width: wheight > wwidth * ratio ? wwidth : wheight / ratio,
        height: wheight > wwidth * ratio ? wwidth * ratio : wheight,
    };
};

const Frame: React.FunctionComponent = (props: PropsWithChildren<unknown>) => {
    const { children } = props;
    const isBrowser = useIsBrowser();
    const isAuthenticated = isBrowser ? useIsAuthenticated() : false;
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const container = useRef(null);

    useEffect(() => {
        function handleResize() {
            const mainDiv = container.current as unknown as HTMLElement;
            const prevDiv = mainDiv.previousSibling as HTMLElement;
            const width = mainDiv.parentElement?.clientWidth || 0;
            const height =
                ((mainDiv.parentElement && mainDiv.parentElement.clientHeight) || 0) -
                (prevDiv && prevDiv.clientHeight);
            setDimensions(
                calculateDimensions({
                    height,
                    width,
                })
            );
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    const iframeClass = mergeStyleSets({
        frameRoot: {
            width: `100%`,
            height: `${dimensions.height}px`,
            flex: `1 1 auto`,
            transitionDelay: `0s`,
            transitionTimingFunction: `linear`,
            transitionDuration: `0.25s`,
            transitionProperty: `all`,
        },
    });

    if (isBrowser && !isAuthenticated) navigate("/");

    return (
        <div className={iframeClass.frameRoot} ref={container}>
            {isBrowser && isAuthenticated && children}
        </div>
    );
};

export default Frame;
