import React from "react";
import { mergeStyleSets } from "@fluentui/react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Frame from "../components/Frame";

const SecureExamplePage: React.FunctionComponent<Record<string, unknown>> = () => {
    const styles = mergeStyleSets({
        header: {
            flex: `0 1 auto`,
        },
        iframeDiv: {
            width: `100%`,
            height: `100%`,
            border: `none`,
        },
        iframe: {
            width: `100%`,
            height: `100%`,
            border: `none`,
        },
    });

    return (
        <Layout>
            <div className={styles.header}>
                <SEO title="Power BI Secure Embed | Example" />
                <h1>Power BI Secure Embed | Example</h1>
            </div>
            <Frame>
                <div className={styles.iframeDiv}>
                    <iframe
                        title="Power Bi Report"
                        src="https://app.powerbi.com/reportEmbed?reportId=a11829f1-0b66-48f3-b1b5-7fdcdf8f5969&autoAuth=true&ctid=187615f5-abbb-49ad-bb8f-25336ff1e97e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
                        allowFullScreen
                        className={styles.iframe}
                    ></iframe>
                </div>
            </Frame>
        </Layout>
    );
};

export default SecureExamplePage;
